// checkLoggedIn.run.js

"use strict";


checkLoggedIn.$inject = ["authService"];
function checkLoggedIn(authService) {
    authService.restoreSession();
}

export { checkLoggedIn };