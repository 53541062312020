// app.scrollToTop.run.js

"use strict";


scroll.$inject = ["$window", "$transitions"];
function scroll($window, $transitions) {
    $transitions.onSuccess({},function() {
        $window.scrollTo(0,0);
    });
}

export { scroll };