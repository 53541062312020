// contratacion-config.service.js
"use strict";

class contratacionConfig {
    constructor($q, authService) {
        this.$q = $q;
        this.authService = authService;
        this.SEL_TRARIFA = 'selTarifa';
        this.SEL_SERVICIOS = "selServicios";
        this.SEL_LUZ = 'selLuz';
        this.SEL_LLAMAMOS_LUZ = 'selLlamamosLuz';
        this.SEL_BONO_SOCIAL = 'selBonoSocial';
        this.FORM_LLAMAMOS_LUZ = 'selFormLlamamosLuz';
        this.CONFIRM_BONO_SOCIAL = 'selConfirmBonoSocial';
        this.FORM_DATOS_PERSONALES = 'selDatosPersonales';
        this.FORM_DATOS_DOMICILIO = 'selDatosDomicilio';
        this.FORM_DOMICILIO_CUPS = 'selDomicilioCups';
        this.FORM_METODO_PAGO = 'selMetodoPago';
        this.FORM_RESUMEN_PEDIDO = 'selResumenPedido';
        this.FORM_FIRMA_DOCUMENTOS = 'selFirmaDocumentos';
        this.FORM_ENVIO_FACTURAS = 'selEnvioFacturas';
        this.SCORING = 'selScoring;'
        this.GENERANDO_CONTRATO = 'selGenerandoContrato';
        this.VALIDACION_TIPO_CLIENTE = 'validacionTipoCliente';
        this.baseConfig = this.getBaseConfig();
    }

    getConfig() {
        let deferred = this.$q.defer();
        deferred.resolve(Object.assign({}, this.baseConfig));
        return deferred.promise;
    }

    getBaseConfig() {
        return {
            [this.SEL_LUZ]: {
                id: this.SEL_LUZ,
                pregunta: '¿Tienes luz en casa?',
                informacion: 'Indicanos si actualmente tienes luz en casa o si habría que dar de alta el suministro eléctrico por primera vez',
                multiseleccion: false,
                respuestas: [
                    {
                        texto: 'Si',
                        seleccionada: false,
                        icono: 'V'
                    },
                    {
                        texto: 'No',
                        seleccionada: false,
                        icono: 'X'
                    }
                ],
                ayuda: {
                    ayuda: {
                        titulo: '¿Por qué pedimos esto?',
                        texto: 'Para saber si hay luz en tu domicilio o si es la primera vez que das de alta un suministro, ya que ahora mismo no podemos proceder con el alta de nuevos suministros.'
                    }
                },
                numeroPaso: 1,
                pasoSiguiente: [this.SEL_BONO_SOCIAL, this.FORM_LLAMAMOS_LUZ]
            },
            [this.SEL_LLAMAMOS_LUZ]:{
                id: this.SEL_LLAMAMOS_LUZ,
                ayuda: {
                    ayuda: {
                        titulo: '¿Por qué pedimos esto?',
                        texto: 'Si todavía no tienes luz en tu domicilio, te detallamos lo necesario para que estés en Energía El Corte Inglés cuanto antes'
                    }
                },
                numeroPaso: 1,
            },
            [this.SEL_BONO_SOCIAL]: {
                id: this.SEL_BONO_SOCIAL,
                pregunta: '¿Eres beneficiario del Bono Social?',
                informacion: 'Selecciona una de estas dos opciones',
                multiseleccion: false,
                respuestas: [
                    {
                        texto: 'Si',
                        seleccionada: false,
                        icono: 'V'
                    },
                    {
                        texto: 'No',
                        seleccionada: false,
                        icono: 'X'
                    }
                ],
                ayuda: {
                    ayuda: {
                        titulo: '¿Por qué pedimos esto?',
                        texto: 'Para nosotros es muy importante que contrates la tarifa que más te convenga. Por eso te preguntamos si tienes Bono Social ya que si es así, nosotros no podremos ofrecértelo.'
                    }
                },
                numeroPaso: 2,
                pasoSiguiente: [this.CONFIRM_BONO_SOCIAL, this.SEL_SERVICIOS, this.FORM_DATOS_PERSONALES]
            },
            [this.CONFIRM_BONO_SOCIAL]:{
                id: this.CONFIRM_BONO_SOCIAL,
                pregunta: '¿Estás interesado a continuar con la contratación renunciando al bono social?',
                informacion: 'Selecciona una de estas opciones:',
                multiseleccion: false,
                respuestas: [
                    {
                        texto: 'Si',
                        seleccionada: false,
                        icono: 'V'
                    },
                    {
                        texto: 'No',
                        seleccionada: false,
                        icono: 'X'
                    }
                ],
                ayuda: {
                    ayuda: {
                        titulo: '¿Por qué pedimos esto?',
                        texto: 'Estaremos encantados de recibirte en Energía El Corte Inglés, pero queremos que tú también estés seguro ya que con nosotros no podrás mantener el bono social'
                    }
                },
                numeroPaso: 2,
                pasoSiguiente: [this.SEL_SERVICIOS,this.FORM_DATOS_PERSONALES]
            },
            [this.SEL_SERVICIOS]:{
                id: this.SEL_SERVICIOS,
                pregunta: '¿Deseas algún servicio adicional?',
                ayuda: {
                    ayuda: {
                        titulo: '¿Cómo pagar estos servicios? ',
                        texto: 'Estos servicios son independientes a la factura de luz. Se te aplicará un coste adicional en función del servicio contratado, que se mostrará en tu factura.'
                    }
                },
                numeroPaso: 3,
                pasoSiguiente: [this.FORM_DATOS_PERSONALES]
            },
            [this.FORM_DATOS_PERSONALES]:{
                id: this.FORM_DATOS_PERSONALES,
                ayuda: {
                    ayuda: {
                        titulo: '¿Para qué necesitamos tus datos?',
                        texto: 'Estos son los datos personales que aparecerán en tu contrato y que utilizaremos cuando hablemos contigo'
                    }
                },
                numeroPaso: 4,
                pasoSiguiente: [this.FORM_DATOS_DOMICILIO]
            },
            [this.FORM_DATOS_DOMICILIO]:{
                id: this.FORM_DATOS_DOMICILIO,
                ayuda: {
                    ayuda: {
                        titulo: '¿Para qué necesitamos tus datos?',
                        texto: 'Estos son los datos del domicilio donde se quiere contratar la tarifa y que aparecerán en el contrato. El CUPS es un número de 20 dígitos que empieza por ES... y que podrá encontrar en cualquiera de tus facturas.'
                    },
                    info: {
                        titulo: 'Información sobre potencia',
                        texto: 'Estás contratando con la misma potencia que tienes en tu comercializadora actual. Si quireres cambiar tu potencia te ayudaremos a elegir la más óptima para ti cuando tu contrato esté activo.'
                    }
                },
                numeroPaso: 5,
                pasoSiguiente: [this.FORM_DOMICILIO_CUPS]
            },
            [this.FORM_DOMICILIO_CUPS]:{
                id: this.FORM_DOMICILIO_CUPS,
                pasoSiguiente: [this.FORM_ENVIO_FACTURAS]
            },
            [this.FORM_ENVIO_FACTURAS]:{
                id: this.FORM_ENVIO_FACTURAS,
                ayuda: {
                    ayuda: {
                        titulo: 'Distintas opciones de envío',
                        texto: '<p>Es recomendable el envío de las facturas por email. En esta pantalla podrás modificar los datos facilitados.</p>En el caso de que prefieras el envío postal puedes especificar una dirección diferente a la facilitada para el suministro.'
                    }
                },
                numeroPaso: 6,
                pasoSiguiente: [this.FORM_METODO_PAGO]
            },
            [this.FORM_METODO_PAGO]:{
                id: this.FORM_METODO_PAGO,
                ayuda: {
                    ayuda: {
                        titulo: '¿Para qué necesitamos estos datos?',
                        texto: 'Puedes elegir donde quieres que facturemos la luz que utilices. Podrás cambiar el método de pago en el futuro desde tu área de clientes personalizada'
                    }
                },
                numeroPaso: 7,
                pasoSiguiente: [this.FORM_RESUMEN_PEDIDO]
            },
            [this.FORM_RESUMEN_PEDIDO]:{
                id: this.FORM_RESUMEN_PEDIDO,
                numeroPaso: 8
            },
            textos: {
                contratacion: {
                    mensajesAlert: {
                        noTieneLuzEnCasa: {
                            titulo: 'Lo sentimos, pero en este momento no podemos dar de alta nuevos suministros de luz',
                            mensaje: 'Esperamos poder contar contigo muy pronto en Energía El Corte Inglés',
                            textoBoton: 'Cerrar'
                        },
                        bonoSocial: {
                            titulo: '¡En Energía El Corte Inglés miramos por ti!',
                            mensaje: 'Actualmente no podemos ofrecerte una tarifa que mejore las ventajas de tu Bono Social. <br>Gracias por tu interés.',
                            textoBoton: 'Cerrar'
                        },
                        dialogoMensaje: {
                            titulo: 'Muchas gracias por tu confianza en Energía El Corte Inglés',
                            textoBoton: 'Cerrar',
                        },
                        checkScoring: {
                            titulo: 'Ups... Parece que algo nos impide continuar con el proceso',
                            mensaje: 'Una incidencia no nos deja continuar. Rellena tus datos de contacto para seguir con el proceso telefónicamente',
                            txtNombre: 'Escribe tu nombre y apellidos',
                            txtTelefono: 'Escribe tu número de teléfono',
                            txtBoton: 'Enviar'
                        },
                        errorComprobandoCups: {
                            error404: {
                                titulo: 'Parece que el CUPS facilitado no nos permite encontrar la potencia que tienes contratada',
                                mensaje: 'No hemos podido encontrar automáticamente la potencia que tienes actualmente. Necesitariamos que nos la facilitaras para poder seguir con la contratación. La podrás encontrar en tu factura, en los detalles de la factura o en el cálculo del importe, o en tu contrato actual.',
                                txtBoton: 'Añadir potencia'
                            },
                            error409: {
                                titulo: 'Ups... Parece que algo nos impide continuar con el proceso',
                                mensaje: 'La potencia no se corresponde con el producto. Rellena tus datos de contacto para seguir con el proceso telefónicamente'
                            },
                            default: {
                                titulo: 'Ups... Parece que algo nos impide continuar con el proceso',
                                mensaje: 'No hemos podido validar tu código CUPS. Rellena tus datos de contacto para seguir con el proceso telefónicamente'
                            }
                        },
                        mostrarDialogoError: {
                            dialogoLead: {
                                titulo: 'Ups... Parece que algo nos impide continuar con el proceso'
                            },
                            dialogoMensaje: {
                                titulo: '¡Lo sentimos!',
                                mensaje: 'Ha habido una incidencia en el sistema que no nos deja proseguir con el contrato por motivos ajenos a Energía El Corte Inglés. En un futuro, si sigues interesado, puedes volver a ponerte en contracto con nosotros'
                            }
                        }
                    },
                    mensajesLead: {
                        contratar: 'Ha sucedido un error al generar el contrato. Una incidencia no nos deja continuar. Introduce los datos para seguir con el proceso telefónicamente',
                        documentos: 'Ha sucedido un error al almacenar los documentos. Una incidencia no nos deja continuar. Introduce los datos para seguir con el proceso telefónicamente',
                        guardandoDatos: 'Ha sucedido un error al almacenar los datos del contrato. Una incidencia no nos deja continuar. Introduce los datos para seguir con el proceso telefónicamente'
                    }
                },
                datosDomicilio: {
                    textoDialogoTeLlamamosCups: {
                        ayuda: '¿Necesitas ayuda?',
                        titulo: '¿Problemas con el CUPS?',
                        mensaje: 'Introduce los datos y en breve te atenderá un comercial de Energía El Corte Inglés',
                        txtCheck: 'Aceptas la información sobre protección de datos'
                    },
                    textoDialogoTeLlamamosPotencia: {
                        ayuda: '¿Necesitas ayuda?',
                        titulo: '¿Problemas con la Potencia?',
                        mensaje: 'Introduce los datos y en breve te atenderá un comercial de Energía El Corte Inglés',
                        txtCheck: 'Aceptas la información sobre protección de datos'
                    },
                    ocultarDesistimiento: true,
                    esViviendaHabitual: '¿Es tu vivienda habitual?'
                },
                metodoPago: {
                    textoDialogoTeLlamamosIban: {
                        ayuda: '¿Necesitas ayuda?',
                        titulo: '¿Problemas con el IBAN?',
                        mensaje: 'Introduce los datos y en breve te atenderá un comercial de Energía El Corte Inglés'
                    },
                    textoDialogoEditarDatosDomicilio: {
                        titulo: 'Introduce la dirección de facturación'
                    }
                }
            }
        };
    }
}

contratacionConfig.$inject = ["$q", "authService"];

export { contratacionConfig as CONTRATACION_CONFIG_SERVICE };
