// auth.interceptor.js
/* globals apiKey, withTrace */
"use strict"

function authInterceptor($window) {
    return {
        request: function(config) {
            config.headers = config.headers || {};
            config.headers['Ocp-Apim-Subscription-Key'] = apiKey;
            config.headers['Ocp-Apim-Trace'] = withTrace;
            return config;
        },
        responseError: function(response) {
            switch (response.status) {
                case 401:
                  $window.location.href = '/login';
                  return;
                default:
                    return response;
            }
        }
    };
}

authInterceptor.$inject = ["$window"];

export { authInterceptor };
