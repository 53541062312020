"use strict"

import { authInterceptor } from "./auth.interceptor";

function configHttp($httpProvider) {
    $httpProvider.interceptors.push(authInterceptor);
}

configHttp.$inject = ["$httpProvider"];

export { configHttp };