// auth.service.js
"use strict";

import angular from "angular";

class authService {
  constructor($window) {
    this.$window = $window;
    this.authData = undefined;
  }

  getAccessTokenHeader() {
    if (!this.authData) {
        return;
    }
    return this.capitalize(this.authData.tokenType) + ' ' + this.authData.accessToken;
  }

  setAuthData(authData) {
    this.authData = Object.assign({}, authData);
  }

  capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  clear() {
      this.authData = undefined;
  }

  getTeci() {
    return this.authData?.teci;
  }

  restoreSession() {
    const session = this.$window.sessionStorage.getItem("sweno.employee");
    if (!session) {
      this.$window.location.href = "/login" + this.$window.location.search;
      return;
    }
    this.setAuthData(angular.fromJson(session));
    this.$window.sessionStorage.removeItem("sweno.employee");
  }
}

authService.$inject = ["$window"];

export { authService as AUTH_SERVICE };