// cookies-dialog.module.js
"use strict";

import angular from "angular";
import { COOKIES_DIALOG_COMPONENT } from "./cookies-dialog.component";

const COOKIES_DIALOG_MODULE = angular
    .module("cookiesDialog", [])
    .component("cookiesDialog", COOKIES_DIALOG_COMPONENT)
    .name
;

export { COOKIES_DIALOG_MODULE };
