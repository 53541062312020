//cookies.component.js
"use strict";

import template from './cookies.view.html';
import { COOKIES_CONTROLLER } from './cookies.controller';
import 'ng-dialog/css/ngDialog.css';
import 'ng-dialog/css/ngDialog-theme-plain.css';
import 'ng-dialog/css/ngDialog-theme-default.css';
import './cookies.less';

let COOKIES_COMPONENT = {
    restrict: 'E',
    bindings: {},
    template,
    controller: COOKIES_CONTROLLER,
    controllerAs: "Cookies"
};

export { COOKIES_COMPONENT };
