//cookies-dialog.component.js
"use strict";

import template from './cookies-dialog.view.html';
import { COOKIES_DIALOG_CONTROLLER } from './cookies-dialog.controller';
import './cookies-dialog.less';

let COOKIES_DIALOG_COMPONENT = {
    restrict: 'E',
    template,
    controller: COOKIES_DIALOG_CONTROLLER,
    controllerAs: "CookiesDialog",
    bindings: {
        accept: '&'
    }
};

export { COOKIES_DIALOG_COMPONENT };
